<template>
  <div>
    <div class="col-12">
      <!-- <div
        class="salert alert alert-custom alert-white alert-shadow fade show gutter-b alert-light"
      >
        <div class="alert-text">
          <div>
            <b-col lg="12" class="my-1">
              <b-form-group
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    v-model="filter"
                    type="search"
                    id="filterInput"
                    placeholder="Search by name"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col lg="12" class="my-1">
              <b-form-group
                label-align-sm="right"
                label-size="sm"
                label-for="sortBySelect"
                class="mb-0"
              >
                {{ sortBy }}
                <b-input-group size="sm">
                  <b-form-select
                    v-model="sortBy"
                    id="sortBySelect"
                    :options="sortOptions"
                    :options2="allstatus"
                    class="w-100"
                  >
                    <template v-for="item2 in statusList">
                      <option :key="item2.id">{{ item2.status }}</option>
                    </template>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
          </div>
        </div>
      </div> -->
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">Mijozlar ruyhati {{ status }}</h3>
          </div>

          <div class="card-button">
            <router-link to="/clientreg/">
              <button class="btn btn-success">Mijoz +</button>
            </router-link>
          </div>
        </div>

        <div class="card-body">
          <div>
            <b-table striped hover :fields="fields" :items="getClinetPerPage">
              <template v-slot:cell(index)="data">{{
                data.index + 1
              }}</template>
              <template v-slot:cell(status.status)="data" class="text-info">
                <b-alert
                  show
                  pill
                  :variant="data.item.status_class"
                  class="p-0 text-center"
                  >{{ data.item.status.status }}</b-alert
                >
              </template>
              <template v-slot:cell(actions)="row">
                <div style="text-align: center">
                  <Dropdown4 v-bind:index="row"></Dropdown4>
                </div>
              </template>
            </b-table>
          </div>
          <!-- <b-table
            striped
            hover
            id="my-table table-transition-example"
            :fields="fields"
            :items="allUsers"
            :tbody-tr-class="rowClass"
            :filterIncludedFields="filterOn"
            :per-page="perPage"
            :current-page="currentPage"
            :busy="isLoading"
            :filter="filter"
            :filter-function="status"
            :sort-direction="sortDirection"
            @filtered="onFiltered"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
          >
            <template v-slot:cell(full_name)="row">
              {{ row.item.full_name }}
            </template>
            <template v-slot:table-busy v-if="isLoading">
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>
            <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
            <template v-slot:cell(status.status)="data" class="text-info">
              <b-alert
                show
                pill
                :variant="data.item.status_class"
                class="p-0 text-center"
                >{{ data.item.status.status }}</b-alert
              >
            </template>
            <template v-slot:cell(actions)="row">
              <div style="text-align:center">
                <Dropdown4 v-bind:index="row"></Dropdown4>
              </div>
            </template>
          </b-table> -->
          <div>
            <!-- <b-pagination
              v-model="currentPage"
              :total-rows="row"
              :per-page="perPage"
              align="right"
              aria-controls="my-table"
            ></b-pagination> -->
            <b-pagination
              :total-rows="getCount"
              v-model="currentPage"
              :per-page="10"
              align="right"
              @input="getPostData(currentPage)"
            >
            </b-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- <v-card>
      <v-flex xs6>
        <v-select
          :items="authors"
          label="Tiru"
          @change="filterAuthor"
        ></v-select>
      </v-flex>
      <v-flex xs6>
        <v-text-field
          append-icon="search"
          label="Mijoz nomi"
          single-line
          hide-details
          @input="filterSearch"
        ></v-text-field>
      </v-flex>

      <v-card-title>
        Mijozlar ruyhati
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field> -->

    <!-- <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-combobox
          v-model="select"
          :items="items"
          label="Search"
        ></v-combobox>
      </v-col>
    </v-row>
  </v-container> -->
    <!-- </v-card-title>
      <v-data-table
        :headers="headers"
        :items="allUsers"
        item-key="name"
        :search="search"
        :custom-filter="customFilter"
      >
        <template slot="headers" slot-scope="props">
          <tr>
            <th v-for="header in props.headers" :key="header.text">
              {{ header.text }}
            </th>
          </tr>
        </template>

        <template slot="items" slot-scope="props">
          <tr>
            <td>{{ props.item.name }}</td>
            <td>{{ props.item.added_by }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-card> -->
  </div>
</template>
<script>
import Dropdown4 from '@/view/content/dropdown/Dropdown4.vue'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
// import axios from "axios";

export default {
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      isOpen: false,
      filterName: null,
      sortable: true,
      sortBy: '',
      count: {},
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      allUsers: {},
      sortByFormatted: true,
      filterByFormatted: true,
      searchStatus: '',
      authors: ['Jismoniy', 'Yuridik'],

      fields: [
        {
          label: '#',
          key: 'index'
        },
        {
          label: 'ID',
          key: 'id'
        },
        {
          label: 'Nomi',
          key: 'full_name'
        },
        {
          label: 'INN',
          key: 'inn'
        },
        {
          label: 'OKED',
          key: 'oked',
          _rowVariant: 'danger'
        },
        {
          label: 'Oblast',
          key: 'adress_oblast.name'
        },
        {
          label: 'Turi',
          key: 'juridical_type'
        },
        {
          label: 'Status',
          key: 'status.status'
        },

        {
          key: 'actions',
          label: '',
          sortable: false
        }
      ],
      search: ''
    }
  },

  components: {
    Dropdown4
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Mijozlar ruyhati' }])
    this.getPostData(this.currentPage)
  },
  beforeCreate() {
    // this.$store.dispatch("getAllUssers");
    this.$store.dispatch('getClientPerPage')

    this.$store.dispatch('getClientStatus')
    // this.$store.dispatch("getPerPage",this.currentPage);
  },
  computed: {
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    statusList() {
      return this.$store.state.requests.clientStatus
    },
    getClinetPerPage() {
      return this.$store.state.requests.clientPerPage
    },
    getCount() {
      return this.$store.state.requests.perpage.count
    },
    row() {
      return this.allUsers.length
    }
  },
  methods: {
    getPostData(value) {
      this.$store.dispatch('getPerPage', value)
    }
  }
}
</script>
